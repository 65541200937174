<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">{{ currentRouteName == "hrd-behavior-evaluation-update" ? 'Edit Record' : 'Add Record' }}</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :options="programs"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :options="batch"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :options="employeeLists"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behavior.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-3 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behavior.join_date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
              </div>

              <div
                class="row g-3"
                v-for="(assessor, index) in assessors"
                :key="index"
              >
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">
                    {{
                      assessor.assessor_employee_name
                        ? assessor.assessor_employee_name
                        : assessor.employee_name
                    }}
                  </p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      v-if="assessor.total_score"
                      class="form-control"
                      v-model="assessor.total_score"
                      disabled
                    />
                    <input
                      type="text"
                      v-else
                      class="form-control"
                      value="0"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="assessorTotalScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4">Average Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="averageScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4">Percentage</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="percentage"
                    disabled
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'hrd-behavior-evaluation' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'hrd-behavior-evaluation-update'
                          ? updateBehaviorEvaluation()
                          : createBehaviorEvaluation()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      programs: [],
      batch: [],
      employeeLists: [],
      assessors: [],
      assessorTotalScore: 0,
      averageScore: 0,
      percentage: 0,
      behavior_assessment_id: null,
      behavior: {
        type: "ORI",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        available: "",
      },
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      behavior: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        available: { required },
      },
    };
  },
  methods: {
    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.clearData();
        this.behavior.program_id = this.selectedProgram.id;
        this.behavior.program_name = this.selectedProgram.name;
        this.behavior.program_short_name = this.selectedProgram.short_name;
        this.getBatchByProgram(this.behavior.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.clearData();
        this.behavior.program_batch_id = this.selectedBatch.id;
        this.behavior.program_batch_no = this.selectedBatch.batch_no;
        this.behavior.available = this.selectedBatch.available;
        this.getEmployeeByBatch(this.behavior.program_batch_id);
      }
    },
    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.behavior.employee_id =
          this.selectedEmployee.employee_id;
        this.behavior.employee_name =
          this.selectedEmployee.employee_name;
        this.behavior.join_date = this.selectedBatch.join_date;
        console.log(this.behavior.employee_id);
        this.getAssessorsByEmployee();
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.assessors = [];
      this.assessorTotalScore = 0;
      this.averageScore = 0;
      this.percentage = 0;
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batches/list/?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batch/employees/list/?batch_id=${batch_id}`
        )
        .then((response) => {
          this.employeeLists = response.data.data[0].employee_ids.map(
            (item) => {
              return {
                employee_name: item.name,
                employee_id: item.employee_ID,
              };
            }
          );

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    async getPrograms() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getAssessorsByEmployee() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/hrd-behavior-evaluation/getassessor/${this.behavior.program_id}/${this.behavior.program_batch_id}/${this.selectedEmployee.employee_id}`
        )
        .then((response) => {
          this.assessors = response.data.data.assessors;
          this.assessorTotalScore = this.assessors.reduce((acc, assessor) => {
            if (Object.prototype.hasOwnProperty.call(assessor, "total_score")) {
              return acc + assessor.total_score;
            } else {
              return acc;
            }
          }, 0);
          this.behavior_assessment_id = response.data.data.behavior_assessment_id;
          
          const questionCount = response.data.data.questionCount * 5;
          this.averageScore = questionCount != 0 ? this.assessorTotalScore / this.assessors.length : 0;
          this.percentage = questionCount != 0 ? Math.round(((this.averageScore / questionCount) * (response.data.data.weight.weight / 100)) * 100) : 0;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.$Progress.fail();
        });
    },
    async createBehaviorEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if(!isFormCorrect) return;
      this.isLoading = true;

      const toSendData = {
        behavior_assessment_id: this.behavior_assessment_id,
        total_score: this.assessorTotalScore,
        average_score: this.averageScore,
        percentage: this.percentage,
      };
      await axios
          .post(`${this.baseUrl}admin/v1/hrd-behavior-evaluation`, toSendData ,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
          )
          .then(() => {
            this.toast.success('Successfully Behavior Evaluation Created.');
            this.$router.push({ name: 'hrd-behavior-evaluation'});
            this.isLoading = false;
          })
          .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.isLoading = false;
          })
    },
    async getBehaviorEvaluationById() {
      this.loading = true;
      await axios 
          .get(`${this.baseUrl}admin/v1/hrd-behavior-evaluation/${this.$route.params.id}`)
          .then((response) => {
            const data = response.data.data;
            this.assessors = data.assessors;
            this.selectedProgram = data.behaviorEvaluationDetails.program_name;
            this.selectedBatch = data.behaviorEvaluationDetails.program_batch_no;
            this.selectedEmployee = data.behaviorEvaluationDetails.employee_name;
            this.behavior = data.behaviorEvaluationDetails;
            this.assessorTotalScore = data.behaviorEvaluationDetails.hrd_behavior_evaluation_lists.total_score;
            this.averageScore = data.behaviorEvaluationDetails.hrd_behavior_evaluation_lists.average_score;
            this.percentage = data.behaviorEvaluationDetails.hrd_behavior_evaluation_lists.percentage;
            this.behavior_assessment_id = data.behaviorEvaluationDetails.hrd_behavior_evaluation_lists.behavior_assessment_id;
            this.loading = false;
          })
          .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.loading = false;
          })
    },
    async updateBehaviorEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if(!isFormCorrect) return;
      
      this.isLoading = true;

      const toSendData = {
        behavior_assessment_id: this.behavior_assessment_id,
        total_score: this.assessorTotalScore,
        average_score: this.averageScore,
        percentage: this.percentage,
      };

      await axios
          .put(`${this.baseUrl}admin/v1/hrd-behavior-evaluation/${this.$route.params.id}`, toSendData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
          )
          .then(() => {
            this.toast.success('Successfully Behavior Evaluation Updated.');
            this.$router.push({ name: 'hrd-behavior-evaluation'});
            this.isLoading = false;
          })
          .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.isLoading = false;
          })

    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },

  created() {
    this.getPrograms();
    if(this.currentRouteName == "hrd-behavior-evaluation-update") {
      this.getBehaviorEvaluationById();
    }
  },

};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
</style>
